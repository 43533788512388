<template>
  <section class="m-merchant-classify m-scroll">
    <!-- 头部导航 -->
    <div class="header">
      <img @click="handleBack" src="@/assets/new-product/icon-fanhui@2x.png" alt="back">
      <div class="search-box">
        <img src="@/assets/public/icon_search@2x.png" alt="search">
        <input v-model="search" type="text" placeholder="请输入关键词" @change="goSearch()">
      </div>
      <router-link to="/" >
        <img src="@/assets/merchant/icon_home_3@2x.png" alt="img">
      </router-link>
    </div>
    <div class="header-bg" :style="`height:${this.offsetTop <= 56 ? 38.5 : 23.46667  }vw;`"></div>
    <div class="merchant-info">
      <img :src="$store.state.merchant.pic_url" alt="merchant">
      <div class="center">
        <p>{{$store.state.merchant.title}}</p>
        <div>
          <div>
            <van-rate  v-model="$store.state.merchant.score" :size="12" color="#F03C18" void-icon="star" void-color="#ccc" readonly/>
          </div>
          <p>{{$store.state.merchant.collect_num}}人关注</p>
        </div>
      </div>
      <div class="followed" @click="handleFollowed()" v-if="$store.state.merchant.is_follow != null">
        <img src="@/assets/merchant/icon_guanzhu.png" alt="">
        <span>已关注</span>
      </div>
      <div class="followed" @click="handleFollowed()" v-if="$store.state.merchant.is_follow == null">
        <img src="@/assets/merchant/icon_guanzhu.png" alt="">
        <span>关注</span>
      </div>
    </div>
    <!-- 分类数据 -->
    <div class="classify-item m-padding" v-for="(it, index) in options" :key="index">
        <div class="title">
          <span>{{it.title}}</span>
          <img src="@/assets/public/icon-fanhui@2x.png" alt="">
        </div>
        <div class="items" >
          <router-link :to="{ path: '/zh/merchant/goodsList', query: { id:$route.params.id, tid: two.id, title:two.title   } }" v-for="(two, ind) in it.two_type" :key="ind">{{two.title}}</router-link>
        </div>
      </div>
    <foot-bar :id="$route.params.id"></foot-bar>
  </section>
</template>

<script>

import FootBar      from '@/components/zh/merchant-footer.vue'
import { classify, follow } from '@/api/zh/merchant.js'

export default {
  name:'MerchantClassify',
  components:{ FootBar },
  data(){
    return {
      offsetTop: 0,
      options: [],
      search: '',
    }
  },
  methods:{
    handleBack(){
      this.$router.go(-1)
    },
    getData() {
      let params = { shop_id: this.$route.params.id, }
      classify(params).then(res => {
        if(res) {
          this.options = res.data
        }
      })
    },
    // 搜索
    goSearch() {
      if(this.search != '') {
        localStorage.setItem('back_path', this.$route.fullPath)
        this.$router.push( { path: '/zh/search', query: {s: 'merchant', key: this.search, id: this.$route.params.id} })
      }
    },
    // 点击关注
    handleFollowed() {
      let params = { shop_id: this.$route.params.id }
      follow(params).then(res => {
        if(res) {
          if(res.msg.length == 4) {
            this.$store.state.merchant.collect_num ++
            this.$store.state.merchant.is_follow = 2
          }else {
            this.$store.state.merchant.collect_num --
            this.$store.state.merchant.is_follow = 1
          }
          this.$notify({ type: 'success', message: res.msg })
        }
      })
    },
  },
  created() {
    this.getData()
  }
}
</script>

<style lang="less" scoped>
@import './classify.less';
</style>
